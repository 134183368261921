import React from 'react';
import PropTypes from 'prop-types';
import {
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';

import Modal from '../../components/Modal';

const propTypes = {
	history: PropTypes.instanceOf(Object).isRequired,
};

const Settings = ({ history }) => (
	<Modal
		history={history}
		title="Settings"
	>
		<List>
			<ListItem>
				<ListItemText primary="App version:" />
				<ListItemSecondaryAction>
					{`v${process.env.REACT_APP_VERSION}`}
				</ListItemSecondaryAction>
			</ListItem>
			{/* <CheckForUpdate /> */}
			{/* <Divider /> */}
			{/* <ChangeTheme /> */}
		</List>
	</Modal>
);

Settings.propTypes = propTypes;

export default Settings;
