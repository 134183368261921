import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	placeholderText: {
		textAlign: 'center',
	},
	list: {
		display: 'flex',
		padding: 0,
		margin: 0,
		flexWrap: 'wrap',
		listStyle: 'none',
		justifyContent: 'center',
	},
	listItem: {
		margin: '16px',
	},
}));

export default useStyles;
