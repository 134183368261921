import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
	palette: {
		primary: {
			main: '#d64512',
		},
	},
	zIndex: {
		appBar: 1350,
	},
});
