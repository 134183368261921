import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	footer: {
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		bottom: 0,
		width: '100%',
		background: 'white',
	},
	footerPlaceholder: {
		minHeight: '56px',
	},
}));

export default useStyles;
