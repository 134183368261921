import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	button: {
		margin: theme.spacing(2),
		width: theme.spacing(20),
	},
	form: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: theme.spacing(2),
	},
	page: {
		overflowY: 'auto',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
}));

export default useStyles;
