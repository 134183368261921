import React from 'react';
import {
	Button,
	TextField,
	Tooltip,
} from '@material-ui/core';

import useStyles from './HomePage.styled';
import ButtonLink from '../../components/ButtonLink';


const fields = [
	{
		default: '',
		field: 'name',
		helperText: 'The card name. For split, double-faced and flip cards, just the name of one side of the card. Basically each ‘sub-card’ has its own record.',
		label: 'Name',
	},
	{
		default: '',
		field: 'layout',
		helperText: 'The card layout. Possible values: normal, split, flip, double-faced, token, plane, scheme, phenomenon, leveler, vanguard, aftermath',
		label: 'Layout',
	},
	{
		default: '',
		field: 'cmc',
		helperText: 'Converted mana cost. Always a number',
		label: 'Converted mana cost (CMC)',
	},
	{
		default: '',
		field: 'colors',
		helperText: 'The card colors. Usually this is derived from the casting cost, but some cards are special (like the back of dual sided cards and Ghostfire).',
		label: 'Colors',
	},
	{
		default: '',
		field: 'colorIdentity',
		helperText: 'The card’s color identity, by color code. [“Red”, “Blue”] becomes [“R”, “U”]. A card’s color identity includes colors from the card’s rules text.',
		label: 'Color identity',
	},
	{
		default: '',
		field: 'type',
		helperText: 'The card type. This is the type you would see on the card if printed today. Note: The dash is a UTF8 ‘long dash’ as per the MTG rules',
		label: 'Type',
	},
	{
		default: '',
		field: 'supertypes',
		helperText: 'The supertypes of the card. These appear to the far left of the card type. Example values: Basic, Legendary, Snow, World, Ongoing',
		label: 'Supertypes',
	},
	{
		default: '',
		field: 'types',
		helperText: 'The types of the card. These appear to the left of the dash in a card type. Example values: Instant, Sorcery, Artifact, Creature, Enchantment, Land, Planeswalker',
		label: 'Types',
	},
	{
		default: '',
		field: 'subtypes',
		helperText: 'The subtypes of the card. These appear to the right of the dash in a card type. Usually each word is its own subtype. Example values: Trap, Arcane, Equipment, Aura, Human, Rat, Squirrel, etc.',
		label: 'Subtypes',
	},
	{
		default: '',
		field: 'rarity',
		helperText: 'The rarity of the card. Examples: Common, Uncommon, Rare, Mythic Rare, Special, Basic Land',
		label: 'Rarity',
	},
	{
		default: '',
		field: 'set',
		helperText: 'The set the card belongs to (set code).',
		label: 'Set',
	},
	{
		default: '',
		field: 'setName',
		helperText: 'The set the card belongs to.',
		label: 'Set name',
	},
	{
		default: '',
		field: 'text',
		helperText: 'The oracle text of the card. May contain mana symbols and other symbols.',
		label: 'Text',
	},
	{
		default: '',
		field: 'flavor',
		helperText: 'The flavor text of the card.',
		label: 'Flavor',
	},
	{
		default: '',
		field: 'artist',
		helperText: 'The artist of the card. This may not match what is on the card as MTGJSON corrects many card misprints.',
		label: 'Artist',
	},
	{
		default: '',
		field: 'number',
		helperText: 'The card number. This is printed at the bottom-center of the card in small text. This is a string, not an integer, because some cards have letters in their numbers.',
		label: 'Number',
	},
	{
		default: '',
		field: 'power',
		helperText: 'The power of the card. This is only present for creatures. This is a string, not an integer, because some cards have powers like: “1+*”',
		label: 'Power',
	},
	{
		default: '',
		field: 'toughness',
		helperText: 'The toughness of the card. This is only present for creatures. This is a string, not an integer, because some cards have toughness like: “1+*”',
		label: 'Toughness',
	},
	{
		default: '',
		field: 'loyalty',
		helperText: 'The loyalty of the card. This is only present for planeswalkers.',
		label: 'Loyalty',
	},
	{
		default: '',
		field: 'language',
		helperText: 'The language the card is printed in. Use this parameter along with the name parameter when searching by foreignName',
		label: 'Language',
	},
	{
		default: '',
		field: 'gameFormat',
		helperText: 'The game format, such as Commander, Standard, Legacy, etc. (when used, legality defaults to Legal unless supplied)',
		label: 'Game format',
	},
	{
		default: '',
		field: 'legality',
		helperText: 'The legality of the card for a given format, such as Legal, Banned or Restricted.',
		label: 'Legality',
	},
	{
		default: '',
		field: 'orderBy',
		helperText: 'The field to order by in the response results',
		label: 'Order by',
	},
	// {
	// 	default: '',
	// 	field: 'random',
	// 	helperText: 'Fetch any number of cards (controlled by pageSize) randomly',
	// 	label: 'Random',
	// },
	{
		default: '',
		field: 'contains',
		helperText: 'Filter cards based on whether or not they have a specific field available (like imageUrl)',
		label: 'Contains',
	},
	{
		default: '',
		field: 'id',
		helperText: 'A unique id for this card. It is made up by doing an SHA1 hash of setCode + cardName + cardImageName',
		label: 'ID',
	},
	{
		default: '',
		field: 'multiverseid',
		helperText: 'The multiverseid of the card on Wizard’s Gatherer web page. Cards from sets that do not exist on Gatherer will NOT have a multiverseid. Sets not on Gatherer are: ATH, ITP, DKM, RQS, DPA and all sets with a 4 letter code that starts with a lowercase ‘p’.',
		label: 'Multiverse ID',
	},
];

const HomePage = () => {
	const classes = useStyles();
	// Sets the default values based on what fields are used
	const [values, setValues] = React.useState(fields
		.reduce((o, key) => ({ ...o, [key.field]: key.default }), {}));

	const handleChange = name => (event) => {
		setValues({ ...values, [name]: event.target.value });
	};

	return (
		<div className={classes.page}>
			<form className={classes.form} noValidate autoComplete="off">
				{fields.map(item => (
					<Tooltip
						key={item.field}
						title={item.helperText}
					>
						<TextField
							id={`mtg-${item.field}`}
							label={item.label}
							className={classes.textField}
							value={values[item.field]}
							onChange={handleChange(item.field)}
							margin="normal"
							fullWidth
						/>
					</Tooltip>
				))}
			</form>

			<Button
				className={classes.button}
				color="primary"
				component={ButtonLink}
				to={{
					pathname: '/search/',
					state: {
						modal: true,
						values,
					},
				}}
				variant="contained"
			>
				Search {/* eslint-disable-line react/jsx-one-expression-per-line */}
			</Button>
		</div>
	);
};

export default HomePage;
