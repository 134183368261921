import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';

import HomePage from '../../pages/HomePage';
import NoPage from '../../pages/NoPage';
import SearchPage from '../../pages/SearchPage';
import Settings from '../../pages/SettingsPage';

export default class Routes extends Component {
	static propTypes = {
		history: PropTypes.instanceOf(Object).isRequired,
		location: PropTypes.instanceOf(Object).isRequired,
	}

	componentDidMount() {
		const { location } = this.props;
		window.previousLocation = location;
	}

	componentDidUpdate({ history, location }) {
		// Set previousLocation if props.location is not a modal
		if (
			history.action !== 'POP'
			&& (!location.state || !location.state.modal)
		) {
			window.previousLocation = location;
		}
	}

	render() {
		const {
			location,
		} = this.props;

		const isModal = !!(
			location
			&& location.state
			&& location.state.modal
			&& window.previousLocation !== location
		);

		return (
			<>
				<Switch location={isModal ? window.previousLocation : location}>
					<Route
						exact
						path="/"
						component={HomePage}
					/>
					<Redirect from="/search/" to="/" />
					<Redirect from="/settings/" to="/" />
					<Route component={NoPage} />
				</Switch>

				{isModal && (
					<Switch>
						<Route
							path="/search/"
							component={SearchPage}
						/>
						<Route
							path="/settings/"
							component={Settings}
						/>
					</Switch>
				)}
			</>
		);
	}
}
