import React from 'react';
import PropTypes from 'prop-types';
import { TablePagination } from '@material-ui/core';

import useStyles from './Pagination.styled';

const propTypes = {
	currentPage: PropTypes.number.isRequired,
	paginate: PropTypes.func.isRequired,
	postsPerPage: PropTypes.number.isRequired,
	setPostsPerPage: PropTypes.func.isRequired,
	totalPosts: PropTypes.number.isRequired,
};

const Pagination = ({
	currentPage,
	paginate,
	postsPerPage,
	setPostsPerPage,
	totalPosts,
}) => {
	const classes = useStyles();
	return (
		<>
			<div className={classes.footerPlaceholder} />
			<footer className={classes.footer}>
				<TablePagination
					component="nav"
					page={currentPage - 1}
					rowsPerPage={postsPerPage}
					rowsPerPageOptions={[10, 20, 30, 40, 50, 75, 100]}
					labelRowsPerPage="Cards per page:"
					count={totalPosts}
					onChangeRowsPerPage={event => setPostsPerPage(event.target.value)}
					// I have added 1 because I have not 0 index my pages
					onChangePage={(event, number) => paginate(number + 1)}
				/>
			</footer>
		</>
	);
};


Pagination.propTypes = propTypes;

export default Pagination;
