import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './Posts.styled';

import placeholderImage from '../../img/placeholder.jpg';

const propTypes = {
	loading: PropTypes.bool.isRequired,
	posts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			imageUrl: PropTypes.string,
			name: PropTypes.string.isRequired,
		}),
	),
};

const Posts = ({ loading, posts }) => {
	const classes = useStyles();

	if (loading) {
		return <h2 className={classes.placeholderText}>Loading...</h2>;
	}
	return (
		<ul className={classes.list}>
			{posts.map(post => (
				<li key={post.id} className={classes.listItem}>
					<img src={post.imageUrl || placeholderImage} alt={post.name} width="200" />
				</li>
			))}
		</ul>
	);
};

Posts.propTypes = propTypes;

export default Posts;
