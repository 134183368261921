import React from 'react';
import PropTypes from 'prop-types';
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import {
	Home as HomeIcon,
} from '@material-ui/icons';

import ButtonLink from '../ButtonLink';

const propTypes = {
	handleDrawerToggle: PropTypes.func.isRequired,
};

const DrawerContent = ({
	handleDrawerToggle,
}) => (
	<>
		<List>
			<ListItem
				button
				component={ButtonLink}
				to="/"
				onClick={handleDrawerToggle}
			>
				<ListItemIcon>
					<HomeIcon />
				</ListItemIcon>
				<ListItemText primary="Home" />
			</ListItem>
		</List>
	</>
);

DrawerContent.propTypes = propTypes;

export default DrawerContent;
