import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	container: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.text.primary,
	},
}));

export default useStyles;
