import React from 'react';
import {
	IconButton,
	Tooltip,
} from '@material-ui/core';
import {
	Settings as SettingsIcon,
} from '@material-ui/icons';

import ButtonLink from '../ButtonLink';

const HeaderContent = () => (
	<>
		<Tooltip title="Settings">
			<IconButton
				aria-label="setting"
				color="inherit"
				edge="end"
				component={ButtonLink}
				to={{
					pathname: '/settings/',
					state: { modal: true },
				}}
			>
				<SettingsIcon />
			</IconButton>
		</Tooltip>
	</>
);

export default HeaderContent;
