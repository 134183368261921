import React from 'react';
import { Route } from 'react-router-dom';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';

import useStyles from './App.styled';
import Routes from '../Routes';
import Container from '../Container';
import theme from '../../theme';

const App = () => {
	const classes = useStyles();

	return (
		<ThemeProvider theme={theme}>
			<StylesProvider injectFirst>
				<main className={classes.container}>
					<Container>
						<Route
							render={props => (
								<Routes {...props} />
							)}
						/>
					</Container>
				</main>
			</StylesProvider>
		</ThemeProvider>
	);
};

export default App;
