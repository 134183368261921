import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import mtg from 'mtgsdk';

import useStyles from './SearchPage.styled';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import Posts from '../../components/Posts';

const propTypes = {
	history: PropTypes.instanceOf(Object).isRequired,
	location: PropTypes.instanceOf(Object).isRequired,
};

const SettingsPage = ({ history, location }) => {
	const classes = useStyles();
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage, setPostsPerPage] = useState(10);

	const searchValues = location.state.values;

	useEffect(() => {
		let isMounted = true;
		setLoading(true);

		// Get all cards
		// let temp = [];
		// mtg.card.all()
		// 	.on('data', (card) => {
		// 		setLoading(false);
		// 		temp = [...temp, card];
		// 		console.log(temp);

		// 		setPosts(temp);
		// 	});

		// Search for card
		mtg.card
			.where(searchValues)
			.then((cards) => {
				if (isMounted) {
					console.log(cards);

					setPosts(cards);
					setLoading(false);
				}
			});

		return () => {
			isMounted = false;
		};
	}, [searchValues]);

	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

	const paginate = pageNumber => setCurrentPage(pageNumber);

	return (
		<Modal
			fullscreen
			history={history}
			title="Search"
		>
			<div className={classes.scroll}>
				<Posts
					loading={loading}
					posts={currentPosts}
				/>
			</div>
			<Pagination
				currentPage={currentPage}
				paginate={paginate}
				postsPerPage={postsPerPage}
				setPostsPerPage={setPostsPerPage}
				totalPosts={posts.length}
			/>
		</Modal>
	);
};

SettingsPage.propTypes = propTypes;

export default SettingsPage;
